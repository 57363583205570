.miscellaneous {
    padding: 0 10%;
    padding-bottom: 12rem;

    gap: 3rem;
}

@media screen and (max-width: 50em) {
    .miscellaneous {
        gap: 1.5rem;
    }

    .title-block-title h5 {
        padding-top: 3rem;

        font-size: 2rem;
    }
}

.miscellaneous-block {
    gap: 1rem;
}
