.conclusion-section {
    padding: 0 10%;
    padding-bottom: 12rem;

    gap: 3rem;
}

@media screen and (max-width: 50em) {
    .conclusion-section {
        gap: 1.5rem;
    }
}
