.identification-section {
    padding: 5% 10%;

    gap: 1.5rem;
}

@media screen and (max-width: 50em) {
    .identification-section {
        padding-bottom: 10%;
    }

    .title-block-title h5 {
        padding-top: 3rem;

        font-size: 2rem;
    }
}
