.home-section {
  height: 100vh;
  width: 100%;
  padding: 0 10%;
}

.home-section-left {
  position: relative;

  width: 60%;
}

.home-section-background-item {
  position: absolute;
  top: 0;
  left: 0;

  height: 20rem;
  width: 0rem;

  background: linear-gradient(
    122.65deg,
    rgba(142, 126, 241, 0.3) 0%,
    rgba(96, 224, 193, 0.05) 73.32%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.home-section-background-item.is-visible {
  height: 25rem;
  width: 15rem;

  filter: opacity(1);
}

.home-section-right {
  margin-bottom: 10rem;
  width: 40%;
}

@media screen and (max-width: 40em) {
  .home-section-right {
    margin-bottom: 8rem;
    width: 60%;
  }

  .home-section-left .title-large {
    align-self: flex-end;
  }
}

.home-section-right-content {
  width: 60%;
}

.home-section-right-icon {
  height: 180px;
  width: fit-content;

  filter: opacity(0);

  transition: all 800ms ease-in-out;
}

.home-section-right-icon.is-visible {
  filter: opacity(1);
}

.home-section-right-text {
  filter: opacity(0);

  transition: all 800ms ease-in-out;
}

.home-section-right-text.is-visible {
  margin-top: 0;

  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .home-section {
    padding: 0 10%;
  }

  .home-section-background-item {
    left: -10%;
  }

  .home-section-right-content {
    width: 80%;
  }
}

@media screen and (max-width: 40em) {
  .home-section {
    padding: 0 1rem;

    flex-direction: column;
  }

  .home-section-left {
    height: 60%;
    width: 100%;
  }

  .home-section-right {
    height: 40%;
    width: 100%;
  }

  .home-section-right-content {
    margin-left: 6rem;
  }

  .home-section-right-icon {
    height: 120px;
  }
}
