.about-section {
  margin-top: 10rem;
  height: fit-content;
  width: 100%;
  padding: 0 10%;
}

@media screen and (max-width: 60em) {
  .about-section {
    margin-top: 5rem;
    height: fit-content;
  }
}

.about-section-grid {
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 60em) {
  .about-section-grid {
    grid-template-columns: 1fr;
    grid-gap: 7.5rem;
  }
}

.about-section-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg) scale(0);

  height: 16rem;
  width: fit-content;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.about-section-image.is-visible {
  transform: translate(-50%, -50%) rotate(0) scale(1);

  filter: opacity(1);
}

@media screen and (max-width: 60em) {
  .about-section-image {
    top: 61.5%;
    left: 100%;

    height: 10rem;
  }
}

.about-section-grid-item-top-left,
.about-section-grid-item-top-right,
.about-section-grid-item-bottom-left,
.about-section-grid-item-bottom-right {
  position: relative;

  height: 22rem;
  width: 100%;

  opacity: 0;

  transition: all 1200ms ease-in-out;
}

.about-section-grid-item-top-left.is-visible,
.about-section-grid-item-top-right.is-visible,
.about-section-grid-item-bottom-left.is-visible,
.about-section-grid-item-bottom-right.is-visible {
  opacity: 1;
}

.about-section-grid-item-bottom-right
{
    margin: 4rem 0 0 3rem;
    padding: 2rem 0 0 2rem;
}

@media screen and (max-width: 60em) {
  .about-section-grid-item-top-left,
  .about-section-grid-item-top-right,
  .about-section-grid-item-bottom-left,
  .about-section-grid-item-bottom-right {
    height: 12rem;
  }

  .about-section-grid-item-bottom-right {
    margin: 0;
    height: 22rem;
    padding: 0;
  }
}

.about-section-grid-item-top-right {
  background: linear-gradient(
    45deg,
    rgba(142, 126, 241, 0.15) 0%,
    rgba(96, 224, 193, 0.05) 100%
  );
}

.about-section-grid-item-bottom-left {
  background: linear-gradient(
    225deg,
    rgba(142, 126, 241, 0.15) 0%,
    rgba(96, 224, 193, 0.05) 100%
  );
}

.about-section-grid-item-top-left-text {
  position: absolute;
  top: 5rem;
  left: 0;

  width: 50%;

  transition: all 1200ms ease-in-out;
}

.about-section-grid-item-top-left-text.is-visible {
  left: 15%;
}

@media screen and (max-width: 60em) {
  .about-section-grid-item-top-left-text {
    left: 0;

    width: 70%;
  }

  .about-section-grid-item-top-left-text.is-visible {
    left: 10%;
  }
}

.about-section-grid-item-top-right-text {
  position: absolute;
  top: 5rem;
  left: 0;

  width: 50%;

  transition: all 1200ms ease-in-out;
}

.about-section-grid-item-top-right-text.is-visible {
  left: 30%;
}

@media screen and (max-width: 60em) {
  .about-section-grid-item-top-right-text {
    top: 2rem;
    left: 0;

    width: 80%;
  }

  .about-section-grid-item-top-right-text.is-visible {
    left: 10%;
  }
}

.about-section-grid-item-bottom-left-text {
  position: absolute;
  top: 8rem;
  left: 30%;

  width: 50%;

  transition: all 1200ms ease-in-out;
}

.about-section-grid-item-bottom-left-text.is-visible {
  left: 15%;
}

@media screen and (max-width: 60em) {
  .about-section-grid-item-bottom-left-text {
    top: 2rem;
    left: 0;

    width: 80%;
  }

  .about-section-grid-item-bottom-left-text.is-visible {
    left: 10%;
  }
}

.about-section-team-image {
  transform: translate(-6rem, 0) scale(0.8);

  object-fit: contain;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.about-section-team-image.is-visible {
  transform: translate(0, 0) scale(1);

  filter: opacity(1);
}
