.gallery-section-one {
  position: relative;

  margin-top: 10rem;
  height: 25vw;
}

.gallery-section-two {
  margin-bottom: 20rem;
}

.gallery-section-one {
  padding: 0rem 10%;

  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
}

.gallery-section-slide-container-one {
  /* position: absolute;
  overflow: hidden;
  height: 80%;
  width: 20%; */

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.gallery-section-slide-container-one img {
  object-fit: cover;
}

.gallery-section-slide-container-one {
  width: 30%;

  filter: grayscale(1);

  transition: all 1200ms ease-in-out;
}

.gallery-section-slide-container-one.is-visible {
  left: 10%;

  height: 100%;
  width: 50%;

  filter: opacity(0.9);
  filter: grayscale(0);
}

.gallery-section-slide-one {
  transform: scale(1.4);

  transition: all 1200ms ease-in-out;
}

.gallery-section-slide-one.is-visible {
  transform: scale(1);
}

@media screen and (max-width: 70em) {
  .gallery-section-one {
    height: 90vw;

    flex-direction: column;
  }

  .gallery-section-slide-container-one.is-visible {
    width: 100%;
  }
}
