@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&family=Rajdhani:wght@300;400;500;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-color: #8E7EF1#303030;
  scrollbar-width: thin;
}

:root {
  --background-color: #252525;
  --hover-color: #fff;
  --white-color: #fff;
  --text-medium-color: #e1e1e1;
  --text-light-color: #ffffff;

  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  background-color: var(--background-color);

  line-height: 1.5;
  font-family: 'Manrope', sans-serif;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-medium-color);
}

img {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 100%;
  width: 100%;
}

video {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* width */
::-webkit-scrollbar {
  width: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #303030;
  /* margin-block: 0.5vh; */
  /* border-radius: 100vw; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 2px solid #303030;
  border-radius: 100vw;

  background: linear-gradient(
    180deg,
    rgba(96, 224, 193, 0.8) 0%,
    rgba(142, 126, 241, 0.8) 100%
  );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #60e0c1 0%, #8e7ef1 100%);
}

button {
  border: none;

  background: none;

  cursor: pointer;
}

input {
  border-radius: 0;

  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  border-radius: 0;

  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  text-decoration: none;
  color: var(--text-medium-color);

  transition: all 400ms ease-in-out;
}

a:hover {
  color: var(--hover-color);
}

a.active {
  color: var(--hover-color);
}

h1 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 4.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

h2 {
  width: fit-content;

  background: linear-gradient(97.24deg, #8e7ef1 0%, #60e0c1 100%);
  background-clip: text;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 7.5rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: transparent;
}

h4 {
  width: fit-content;

  background: linear-gradient(248.84deg, #60e0c1 38.53%, #8e7ef1 100%);
  background-clip: text;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: transparent;
}

h3 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

h5 {
  width: fit-content;

  background: linear-gradient(97.24deg, #8e7ef1 0%, #60e0c1 100%);
  background-clip: text;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: transparent;
}

h6 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

p {
  line-height: 1.5;
  font-family: 'Manrope', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.04em;
}

@media screen and (max-width: 70em) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 5.5rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 4.5rem;
  }

  p {
    font-size: 1rem;
  }
}

.error-page {
  height: 100vh;
  width: 100%;
}

.title-small,
.title-large,
.title-medium {
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.title-small.is-visible,
.title-large.is-visible,
.title-medium.is-visible {
  filter: opacity(1);
}

.title-h1 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 4.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

.title-h6 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

.title-h5 {
  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);
}

.loader-content {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background-color: var(--background-color);

  transition: all 400ms ease-out;
}

.loader-content-container {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  height: 160px;
  width: 320px;
}

.loader-content-container img {
  position: absolute;
  bottom: 0;
  left: 0;

  height: 100%;
  width: 100%;

  object-fit: contain;

  opacity: 1;

  animation: loaderImageAnimation 1s ease-in-out;
}

@keyframes loaderImageAnimation {
  0% {
    bottom: -5rem;

    opacity: 0;
  }
}

.loader-content-text-container {
  position: absolute;
  bottom: 20%;
  left: calc(50% - 5rem);
}

.loader-content-text-container p {
  width: 10rem;

  line-height: 1.5;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-align: center;

  animation: textAnimation 3s ease-in-out;
}

@keyframes textAnimation {
  0% {
    filter: opacity(0);
  }

  20% {
    filter: opacity(0.4);
  }

  40% {
    filter: opacity(1);
  }

  60% {
    filter: opacity(0.4);
  }

  80% {
    filter: opacity(1);
  }

  100% {
    filter: opacity(0);
  }
}

.loader-container-background-one {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 70%;
  width: 70%;

  display: flex;
  justify-content: center;
  align-items: center;

  filter: opacity(0.6);
}

.loader-container-background-vignette {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 150rem;
  width: 150rem;

  background: radial-gradient(
    58.45% 58.45% at 50% 50%,
    rgba(37, 37, 37, 0) 0%,
    rgba(37, 37, 37, 0.6) 10%,
    rgba(37, 37, 37, 1) 20%
  );
}

@media screen and (max-width: 50em) {
  .loader-container-background-vignette {
    height: 60rem;
    width: 60rem;
  }

  .loader-content-container {
    height: 112px;
    width: 224px;
  }

  .loader-content-container p {
    font-size: 1rem;
  }
}

.btn {
  height: 4rem;
  width: fit-content;

  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  background: none;

  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-medium-color);

  cursor: pointer;
}

.btn img {
  width: fit-content;
}

.btn:hover {
  gap: 2.5rem;

  color: var(--text-light-color);
}

.btn-primary {
  filter: opacity(0);

  transition: all 400ms ease-in-out;
}

.btn-primary.is-visible {
  filter: opacity(1);
}

.home-screen-container {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  will-change: transform;
  overflow-x: hidden;
}

.error-page-title {
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.error-page-title.is-visible {
  filter: opacity(1);
}

.background {
  z-index: -3;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  filter: opacity(0.6);

  animation: backgroundAnimation 400ms ease-in;
}

@keyframes backgroundAnimation {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(0.6);
  }
}

.background-vignette {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  background: radial-gradient(
    58.45% 58.45% at 50% 50%,
    rgba(37, 37, 37, 0) 0%,
    rgba(37, 37, 37, 1) 77.46%
  );
}

.hero-section {
    height: 30rem;
    width: 100%;
    padding: 0 10%;

    align-items: flex-end;
}

.content-section {
    padding: 5% 10%;

    flex-direction: column;
    gap: 1.5rem;
}

@media screen and (max-width: 50em) {
    .content-section {
        padding-bottom: 10%;
    }
}

.content-section:last-child
{
    margin-bottom: 8rem;
}

.content-section * {
    line-height: 1.5;
    font-family: 'Manrope', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.04em;
}

.content-section-title {
    padding-top: 3rem;

    font-size: 2rem;

    width: fit-content;
    background: linear-gradient(97.24deg, #8e7ef1 0%, #60e0c1 100%);
    background-clip: text;
    line-height: 1;
    font-family: 'Rajdhani', sans-serif;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: transparent;
    margin-bottom: 1rem;
}

.content-section-subtitle {
    padding-top: 3rem;

    font-size: 2rem;
    margin-bottom: 1rem;
}

.content-section-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1rem;
}

.content-section-line {
    margin-left: 3rem;
    font-size: 1rem;
    margin-top: 1rem;
}

.content-section-paragraph {
    font-size: 1rem;
    margin-top: 1rem;
}

.content-section-paragraph sup {
    font-size: 0.8rem;
}

.content-section table {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-collapse: separate;
    border-spacing: 10px;
    width: 80%;
    margin: 20px auto;
    border: 2px solid #333;
}

.content-section td,
.content-section th {
      padding: 15px;
      border: 1px solid #666;
}

.content-section button
{
    border: 1px solid white;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}
