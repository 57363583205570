.contact-section {
  position: relative;

  margin-top: 10rem;
  height: 150vh;
}

@media screen and (max-width: 60em) {
  .contact-section {
    margin-top: 0;
    margin-bottom: 4rem;
    height: 100vh;
  }
}

.contact-section-title {
  position: absolute;
  top: 10rem;
  right: 20%;
}

.contact-section-title p {
  margin-left: 10rem;
  width: 20rem;
  width: fit-content;
}

@media screen and (max-width: 60em) {
  .contact-section-title {
    top: 5rem;
    top: 2rem;
    left: 30%;
  }

  .contact-section-title h2 {
    font-size: 4rem;
  }

  .contact-section-title p {
    margin-left: 0;
  }
}

@media screen and (max-width: 40em) {
  .contact-section-title {
    top: 5rem;
    top: 2rem;
    left: 15%;

    width: 80%;
  }
}

.contact-section-background-top {
  position: absolute;
  top: 0;
  left: 10%;

  height: 0;
  width: 0;

  background: linear-gradient(
    315deg,
    rgba(142, 126, 241, 0.15) 0%,
    rgba(96, 224, 193, 0.05) 100%
  );

  transition: all 1200ms ease-in-out;
}

.contact-section-background-top.is-visible {
  height: 30%;
  width: 10%;
}

@media screen and (max-width: 60em) {
  .contact-section-background-top {
    left: 0;
  }
}

.contact-section-background-bottom {
  z-index: -1;
  position: absolute;
  top: 80%;
  right: 20%;

  height: 0;
  width: 0;

  background: linear-gradient(
    45deg,
    rgba(142, 126, 241, 0.05) 0%,
    rgba(96, 224, 193, 0.1) 100%
  );

  transition: all 1200ms ease-in-out;
}

.contact-section-background-bottom.is-visible {
  height: 20%;
  width: 50%;
}

@media screen and (max-width: 60em) {
  .contact-section-background-bottom {
    top: 60%;
    right: 0;
  }
}

.contact-section-background-right {
  position: absolute;
  top: 50%;
  right: 10%;

  height: 0;
  width: 0;

  background: linear-gradient(
    315deg,
    rgba(142, 126, 241, 0.05) 20%,
    rgba(96, 224, 193, 0.1) 100%
  );

  transition: all 1200ms ease-in-out;
}

.contact-section-background-right.is-visible {
  height: 30%;
  width: 10%;
}

@media screen and (max-width: 60em) {
  .contact-section-background-right {
    top: 30%;
    right: 0;
  }
}

.contact-section-background-circle {
  position: absolute;
  top: calc(30% - 5rem);
  left: calc(20% - 5rem);
  transform: rotate(-180deg) scale(0);

  height: 10rem;
  width: 10rem;

  transition: all 1200ms ease-in-out;
}

.contact-section-background-circle.is-visible {
  transform: rotate(0deg) scale(1);
}

@media screen and (max-width: 60em) {
  .contact-section-background-circle {
    top: calc(30% - 3rem);
    left: calc(10% - 5rem);

    height: 6rem;
  }
}

.contact-section-background-square {
  position: absolute;
  top: calc(80% - 5rem);
  right: calc(20% - 5rem);
  transform: rotate(-180deg) scale(0);

  height: 10rem;
  width: 10rem;

  transition: all 1200ms ease-in-out;
}

.contact-section-background-square.is-visible {
  transform: rotate(0deg) scale(1);
}

@media screen and (max-width: 60em) {
  .contact-section-background-square {
    top: calc(60% - 3rem);
    right: calc(10% - 5rem);

    height: 6rem;
  }
}

.contact-section-map-container {
  position: absolute;
  top: 30%;
  left: 20%;

  overflow: hidden;
  height: 20%;
  width: 10%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.contact-section-map {
  position: absolute;
  top: 0;
  left: 0;

  height: fit-content;
  width: fit-content;
}

.gmnoprint {
  height: fit-content;

  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style-iw-d {
  padding: 0;

  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style-iw {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style-iw-c {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style-iw {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style-iw + .gm-style-iw-c {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style + .gm-style-iw-c {
  background: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.gm-style .gm-style-iw-t::after {
  background: none;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: none;
}

.contact-section-map-container.is-visible {
  height: 50%;
  width: 60%;

  filter: opacity(1);
}

@media screen and (max-width: 60em) {
  .contact-section-map-container {
    left: 10%;
  }

  .contact-section-map-container.is-visible {
    height: 30%;
    width: 80%;
  }
}

.contact-section-item-container {
  position: absolute;
  top: 85%;
  left: 20%;

  width: 70%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.contact-section-item-container.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 60em) {
  .contact-section-item-container {
    top: 65%;
    left: 10%;

    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
}

.contact-section-item-title {
  background: linear-gradient(251.92deg, #60e0c1 0%, #8e7ef1 100%);
  background-clip: text;

  line-height: 1;
  font-family: Rajdhani;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: transparent;
}

.contact-section-item-text {
  line-height: 1;
  font-family: Rajdhani;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-medium-color);
}
