.navigation-container {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;

  width: 10rem;

  display: flex;

  background: linear-gradient(
    58.93deg,
    rgba(142, 126, 241, 0.1) 0%,
    rgba(96, 224, 193, 0) 81.15%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.navigation-container.is-visible {
  right: 0;

  filter: opacity(1);
}

.navigation-list {
  position: absolute;
  top: 12rem;
  left: -0.75rem;

  height: fit-content;
}

.navigation-item {
  opacity: 0.6;

  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.navigation-item p {
  line-height: 1.5;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (max-width: 80em) {
  .navigation-container {
    display: none;
  }
}
