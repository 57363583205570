.header-container {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  padding: 2rem;

  background: linear-gradient(180deg, #252525 0%, rgba(37, 37, 37, 0) 100%);
  filter: opacity(1);

  transition: all 400ms ease-in-out;
}

.header-project-logo img {
  height: 3rem;
  width: 100%;

  cursor: pointer;
  transition: 400ms ease-in-out;
}

.header-container.active > .header-project-logo img {
  height: 5rem;
  width: 100%;

  transition: 400ms ease-in-out;
}

.header-mobile-menu-content {
  z-index: 12;
}

.header-container-mobile-menu {
  z-index: 1;

  display: none;

  cursor: pointer;
  transition: all 400ms ease-in;
}

.header-mobile-menu {
  z-index: 100;
  position: fixed;
  top: 0;
  right: -100%;

  height: 100vh;
  width: 70%;

  opacity: 0;
  background-color: var(--background-color);

  transition: all 800ms ease-in-out;
}

.header-mobile-menu.active {
  right: 0rem;

  opacity: 1;
}

.header-mobile-menu-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: linear-gradient(
    122.65deg,
    rgba(142, 126, 241, 0.1) 0%,
    rgba(96, 224, 193, 0) 73.32%
  );
}

.header-mobile-menu-item {
  z-index: 12;

  width: fit-content;

  line-height: 1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: var(--text-light-color);

  cursor: pointer;
}

.header-mobile-menu-item p {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.header-mobile-menu-close {
  position: absolute;
  top: 2rem;
  right: 2rem;

  opacity: 0;

  cursor: pointer;
}

.header-mobile-menu-close.active {
  opacity: 1;

  transition: all 1000ms ease-in-out;
}

@media screen and (max-width: 80em) {
  .header-container {
    padding: 1rem;
  }

  .header-container-mobile-menu {
    display: flex;
  }

  .header-project-logo img {
    top: 1rem;
    left: 1rem;

    width: 10rem;
  }
}
