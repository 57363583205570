.our-strength-section {
  margin-top: 10rem;
  height: fit-content;
  width: 100%;
  padding: 0 10%;

  gap: 7rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section {
    margin-top: 7rem;

    gap: 4rem;
  }
}

.our-strength-section-block-strength-quality,
.our-strength-section-block-innovative-products,
.our-strength-section-block-flexibility,
.our-strength-section-block-structure {
  position: relative;

  margin-bottom: 10rem;
  height: 60vh;
  width: 100%;

  gap: 10rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-strength-quality,
  .our-strength-section-block-innovative-products,
  .our-strength-section-block-flexibility,
  .our-strength-section-block-structure {
    margin-bottom: 5rem;
    height: fit-content;

    flex-direction: column;
    gap: 5rem;
  }
}

.our-strength-section-block-strength-quality-image-container {
  position: relative;

  overflow: hidden;
  width: 10%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-strength-quality-image-container.is-visible {
  width: 50%;

  filter: opacity(0.8);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-strength-quality-image-container.is-visible {
    height: 15rem;
    width: 100%;
  }
}

.our-strength-section-block-strength-quality-image {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: scale(1.4);

  object-fit: cover;

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-strength-quality-image.is-visible {
  top: 0;
  left: 0;
  transform: scale(1);
}

.our-strength-section-block-strength-quality-content-container {
  position: relative;

  width: 50%;
  padding-top: 7rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-strength-quality-content-container {
    height: 70%;
    width: 100%;
  }
}

.our-strength-section-block-strength-quality-content-text-top,
.our-strength-section-block-strength-quality-content-text-bottom {
  width: 60%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-strength-quality-content-text-top.is-visible,
.our-strength-section-block-strength-quality-content-text-bottom.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-strength-quality-content-text-top,
  .our-strength-section-block-strength-quality-content-text-bottom {
    width: 80%;
  }
}

.our-strength-section-block-strength-quality-content-background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  width: 10%;

  background: linear-gradient(
    109.62deg,
    rgba(96, 224, 193, 0.2) 0%,
    rgba(142, 126, 241, 0.05) 60%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-strength-quality-content-background {
    height: 110%;
  }
}

.our-strength-section-block-strength-quality-content-background.is-visible {
  width: 80%;

  filter: opacity(1);
}

.our-strength-section-innovative-products-quality-content-container {
  position: relative;

  width: 50%;
  padding-left: 15%;

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section-innovative-products-quality-content-container {
    height: 70%;
    width: 100%;
  }
}

.our-strength-section-innovative-products-quality-content-text-top {
  width: 60%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-innovative-products-quality-content-text-bottom {
  width: 60%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-innovative-products-quality-content-text-top.is-visible,
.our-strength-section-innovative-products-quality-content-text-bottom.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .our-strength-section-innovative-products-quality-content-text-top,
  .our-strength-section-innovative-products-quality-content-text-bottom {
    width: 90%;
  }
}

.our-strength-section-innovative-products-quality-content-top-background {
  z-index: -1;
  position: absolute;
  top: 10%;
  left: 0;

  height: 10%;
  width: 10%;

  background: linear-gradient(
    45deg,
    rgba(96, 224, 193, 0.2) 0%,
    rgba(142, 126, 241, 0.1) 100%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-innovative-products-quality-content-top-background.is-visible {
  height: 60%;
  width: 70%;

  filter: opacity(1);
}

.our-strength-section-innovative-products-quality-content-bottom-background {
  z-index: -1;
  position: absolute;
  top: 70%;
  right: 0;

  height: 0;
  width: 0;

  background: linear-gradient(
    135deg,
    rgba(142, 126, 241, 0.2) 0%,
    rgba(96, 224, 193, 0.1) 100%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-innovative-products-quality-content-bottom-background.is-visible {
  height: 30%;
  width: 30%;

  filter: opacity(1);
}

.our-strength-section-block-innovative-products-image-container {
  position: relative;

  overflow: hidden;
  height: 60%;
  width: 10%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-innovative-products-image-container.is-visible {
  height: 100%;
  width: 50%;

  filter: opacity(0.8);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-innovative-products-image-container.is-visible {
    height: 15rem;
    width: 100%;
  }
}

.our-strength-section-innovative-products-quality-image {
  position: absolute;
  top: 0;
  left: -60%;
  transform: scale(1.4);

  object-fit: cover;

  transition: all 1200ms ease-in-out;
}

.our-strength-section-innovative-products-quality-image.is-visible {
  left: 0;
  transform: scale(1);
}

.our-strength-section-block-flexibility-image-container {
  position: relative;

  overflow: hidden;
  height: 80%;
  width: 10%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-flexibility-image-container.is-visible {
  height: 100%;
  width: 50%;

  filter: opacity(0.8);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-flexibility-image-container.is-visible {
    height: 15rem;
    width: 100%;
  }
}

.our-strength-section-block-flexibility-image {
  position: absolute;
  top: 50%;
  right: -50%;
  transform: scale(1.4);

  object-fit: cover;

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-flexibility-image.is-visible {
  top: 0;
  right: 0;
  transform: scale(1);
}

.our-strength-section-block-flexibility-content-container {
  position: relative;

  width: 50%;
  padding-top: 10rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-flexibility-content-container {
    height: 70%;
    width: 100%;
    padding-top: 5rem;
  }
}

.our-strength-section-block-flexibility-content-text-top {
  width: 60%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-flexibility-content-text-bottom {
  width: 60%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-flexibility-content-text-top.is-visible,
.our-strength-section-block-flexibility-content-text-bottom.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-flexibility-content-text-top {
    width: 80%;
  }

  .our-strength-section-block-flexibility-content-text-bottom {
    width: 80%;
  }
}

.our-strength-section-block-flexibility-content-background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;

  height: 120%;
  width: 10%;

  background: linear-gradient(
    45deg,
    rgba(85, 73, 163, 0.2) 0%,
    rgba(96, 224, 193, 0.1) 100%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-flexibility-content-background.is-visible {
  height: 100%;
  width: 80%;

  filter: opacity(1);
}

.our-strength-section-structure-quality-content-container {
  position: relative;

  width: 50%;
  padding-top: 12rem;
  padding-left: 15%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (max-width: 70em) {
  .our-strength-section-structure-quality-content-container {
    height: 70%;
    width: 100%;
    padding-top: 5rem;
  }
}

.our-strength-section-structure-quality-content-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;

  height: 80%;
  width: 10%;

  background: linear-gradient(
    45deg,
    rgba(142, 126, 241, 0.1) 0%,
    rgba(96, 224, 193, 0.2) 100%
  );
  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-structure-quality-content-background.is-visible {
  height: 100%;
  width: 70%;

  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .our-strength-section-structure-quality-content-background.is-visible {
    width: 80%;
  }
}

.our-strength-section-block-structure-image-container {
  position: relative;

  overflow: hidden;
  width: 10%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-structure-image-container.is-visible {
  width: 50%;

  filter: opacity(0.8);
}

@media screen and (max-width: 70em) {
  .our-strength-section-block-structure-image-container.is-visible {
    height: 15rem;
    width: 100%;
  }
}

.our-strength-section-structure-quality-image {
  position: absolute;
  top: 60%;
  right: 60%;
  transform: scale(1.4);

  object-fit: cover;

  transition: all 1200ms ease-in-out;
}

.our-strength-section-structure-quality-image.is-visible {
  top: 0;
  right: 0;
  transform: scale(1);
}

.our-strength-section-structure-quality-content-text {
  width: 80%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-structure-quality-content-text.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 70em) {
  .our-strength-section-structure-quality-content-text {
    width: 100%;
  }
}

.our-strength-section-block-flexibility-element-container {
  z-index: 1;
  position: absolute;
  top: calc(100% - 4rem);
  left: calc(50% - 8.6rem);

  filter: opacity(0);

  transition: all 4s ease-in-out;
}

.our-strength-section-block-flexibility-element-container.is-visible {
  filter: opacity(1);
}

.our-strength-section-block-flexibility-element-left {
  position: absolute;
  top: 0;
  left: 0;

  animation: atomAnimationLeft 5s infinite;
}

@keyframes atomAnimationLeft {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.our-strength-section-block-flexibility-element-right {
  animation: atomAnimationRight 5s infinite;
}

@keyframes atomAnimationRight {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.our-strength-section-block-strength-quality-element-container {
  position: absolute;
  top: -5rem;
  left: 80%;

  height: 10rem;
  width: 10rem;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.our-strength-section-block-strength-quality-element-container.is-visible {
  filter: opacity(1);
}

@media screen and (max-width: 60em) {
  .our-strength-section-block-strength-quality-element-container {
    top: 1rem;

    height: 8rem;
    width: 8rem;
  }
}

.our-strength-section-block-strength-quality-element-right {
  position: absolute;
  top: 1rem;
  left: 1rem;

  height: 80%;
  width: 80%;

  animation: ellipseAnimation 15s infinite;
}

@keyframes ellipseAnimation {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0);
  }
}

.our-strength-section-block-structure-element-container {
  position: absolute;
  top: calc(100% - 5rem);
  left: calc(35% - 8rem);

  height: 10rem;
  width: 10rem;

  filter: opacity(0);

  transition: all 4s ease-in-out;
  animation: circleAnimation 5s infinite;
}

@keyframes circleAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.our-strength-section-block-structure-element-container.is-visible {
  filter: opacity(1);
}
