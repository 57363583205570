.general-conditions {
    padding: 0 10%;
    padding-bottom: 5%;

    gap: 3rem;
}

@media screen and (max-width: 50em) {
    .general-conditions {
        padding-bottom: 10%;

        gap: 1.5rem;
    }

    .title-block-title h5 {
        padding-top: 3rem;

        font-size: 2rem;
    }
}

.general-conditions-block {
    gap: 0.5rem;
}
