.terms-section {
    padding: 0 10%;
    padding-bottom: 5%;

    gap: 1.5rem;
}

.terms-section-title {
}

.terms-section-text {
}

.terms-section-bullet {
    margin-left: 2em;
}

.terms-section-bullet::before
{
    position: absolute;
    transform: translateX( -2em );

    content: "\2022";
}
