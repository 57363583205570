.offer-section {
  position: relative;

  margin-top: 10rem;
  height: fit-content;
  padding: 0 10%;
}

@media screen and (max-width: 60em) {
  .offer-section {
    margin-top: 5rem;
    margin-bottom: 15rem;
  }
}

.offer-section-background-top,
.offer-section-background-center-one,
.offer-section-background-center-two,
.offer-section-background-bottom,
.offer-section-background-container {
  position: absolute;
}

.offer-section-background-top {
  z-index: -1;
  top: 0;
  left: 15%;

  height: 10rem;
  width: 0rem;

  background: linear-gradient(
    45deg,
    rgba(142, 126, 241, 0.1) 50%,
    rgba(96, 224, 193, 0.2) 100%
  );

  transition: all 1200ms ease-in-out;
}

.offer-section-background-top.is-visible {
  height: 40rem;
  width: 20rem;
}

@media screen and (max-width: 60em) {
  .offer-section-background-top.is-visible {
    height: 25rem;
    width: 15rem;
  }
}

.offer-section-background-bottom {
  z-index: -1;
  top: calc(100% - 20rem);
  right: 10%;

  height: 5rem;
  width: 5rem;

  background: linear-gradient(
    45deg,
    rgba(96, 224, 193, 0.2) 0%,
    rgba(142, 126, 241, 0.05) 80%
  );

  transition: all 1200ms ease-in-out;
}

.offer-section-background-bottom.is-visible {
  height: 20rem;
  width: 24rem;
}

@media screen and (max-width: 60em) {
  .offer-section-background-bottom {
    top: calc(100% - 25rem);
  }
}

.offer-section-background-container {
  z-index: -1;
  top: calc(50% + 10rem);
  left: 50%;
  transform: translate(-50%, -50%);

  height: 40rem;
  width: 60rem;
}

.offer-section-background-center-one {
  top: 50%;
  left: 0;

  height: 0;
  width: 0;

  background: linear-gradient(
    45deg,
    rgba(142, 126, 241, 0.05) 20%,
    rgba(96, 224, 193, 0.15) 100%
  );

  transition: all 1200ms ease-in-out;
}

.offer-section-background-center-two {
  top: 0;
  right: 0;

  height: 0;
  width: 0;

  background: linear-gradient(
    45deg,
    rgba(96, 224, 193, 0.15) 0%,
    rgba(142, 126, 241, 0.05) 100%
  );

  transition: all 1200ms ease-in-out;
}

.offer-section-background-center-one.is-visible,
.offer-section-background-center-two.is-visible {
  height: 50%;
  width: 50%;
}

.offer-section-title {
  margin-top: 5rem;
  width: 35rem;
}

@media screen and (max-width: 60em) {
  .offer-section-title {
    width: 16rem;
  }
}

.offer-section-content {
  position: relative;

  margin: 10rem 15rem 15rem;

  gap: 30rem;
}

@media screen and (max-width: 60em) {
  .offer-section-content {
    margin: 5rem 5% 10rem;
    width: 100%;

    flex-direction: column;
  }

  .offer-section-row {
    width: 100%;

    flex-direction: column;
  }
}

.offer-section-mobility-players,
.offer-section-luxury-cars,
.offer-section-industries,
.offer-section-oem,
.offer-section-design,
.offer-section-classic-cars {
  width: 20rem;
}

@media screen and (max-width: 60em) {
  .offer-section-mobility-players,
  .offer-section-luxury-cars,
  .offer-section-industries,
  .offer-section-oem,
  .offer-section-design,
  .offer-section-classic-cars {
    width: 15rem;
  }
}

@media screen and (max-width: 60em) {
  .offer-section-design {
    margin-top: 2rem;
  }
}

.offer-section-luxury-cars {
  align-self: flex-end;
}

.offer-section-oem {
  align-self: flex-end;
}

.offer-section-industries {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  align-self: center;
}

.offer-section-classic-cars {
  position: absolute;
  bottom: -40%;
  left: 52.5%;
  transform: translate(-50%, -50%);

  align-self: center;
}

@media screen and (max-width: 60em) {
  .offer-section-classic-cars {
    bottom: 0;
    right: 0;
    transform: translate(-50%, 125%);
  }
}

.offer-section-mobility-players-icon,
.offer-section-luxury-cars-icon,
.offer-section-industries-icon,
.offer-section-oem-icon,
.offer-section-design-icon,
.offer-section-classic-cars-icon {
  height: 15rem;
  width: fit-content;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.offer-section-mobility-players-icon.is-visible,
.offer-section-luxury-cars-icon.is-visible,
.offer-section-industries-icon.is-visible,
.offer-section-oem-icon.is-visible,
.offer-section-design-icon.is-visible,
.offer-section-classic-cars-icon.is-visible {
  filter: opacity(1);
}

.offer-section-mobility-players-text,
.offer-section-luxury-cars-text,
.offer-section-industries-text,
.offer-section-oem-text,
.offer-section-design-text,
.offer-section-classic-cars-text {
  width: 100%;

  filter: opacity(0);

  transition: all 1200ms ease-in-out;
}

.offer-section-mobility-players-text.is-visible,
.offer-section-luxury-cars-text.is-visible,
.offer-section-industries-text.is-visible,
.offer-section-oem-text.is-visible,
.offer-section-design-text.is-visible,
.offer-section-classic-cars-text.is-visible {
  filter: opacity(1);
}
