.cookies {
    padding: 0 10%;
    padding-bottom: 5%;

    gap: 3rem;
}

@media screen and (max-width: 50em) {
    .cookies {
        padding-bottom: 10%;

        gap: 1.5rem;
    }

    .title-block-title h5 {
        padding-top: 3rem;

        font-size: 2rem;
    }
}

.cookies-block {
    gap: 1rem;
}

.cookies-block-table {
    overflow-y: scroll;
    border: 1px solid white;
    padding: 3rem;

    gap: 3rem;
}

.cookies-block-table-block {
    flex: 1;
    gap: 1rem;
}

.cookies-block-table-block p {
    height: 16rem;

    font-size: 1rem;
}

@media screen and (max-width: 50em) {
    .cookies-block-table-block p {
        height: 14rem;
        width: 328px;
    }
}

.cookies-block-table-block p:last-child {
    height: 12rem;
}

.cookies-block-table-block-title {
    width: 100%;
    border-bottom: 1px solid white;
    padding-bottom: 1rem;

    line-height: 1;
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.04em;
    color: var(--text-light-color);
}
