.cookie-consent-banner
{
    z-index: 9000;
    position: fixed;
    bottom: 0;
    left: 0;

    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 20rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;

    background-color: white;
    box-shadow: 0 0.25rem 0.5rem rgba( 128, 128, 128, 0.5 );

    font-size: 0.9rem;
    font-weight: 500;
    color: black;
}

.cookie-consent-banner-container a,
.cookie-consent-banner-container a:visited
{
    text-decoration: underline;
    color: #202020;
}

.cookie-consent-banner-text
{
    color: #202020;
}

.cookie-consent-banner-link
{
    margin-top: 0.25rem;

    font-weight: 700;
}

.cookie-consent-banner-link a
{
    color: #7069BF;
}

.cookie-consent-banner-link:hover a
{
    color: #4FAF9D;
}

.cookie-consent-banner-button-list
{
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cookie-consent-banner-button
{
    border-radius: 0.25rem;
    padding: 0.25rem 1rem;

    font-weight: 500;
    text-align: center;
    color: white;

    cursor: pointer;
    transition: background-color 0.25s ease;
}

.cookie-consent-banner-cookies-settings-button
{
    border: 1px solid #D0D0D0;

    background-color: #F0F0F0;

    color: #808080;
}

.cookie-consent-banner-cookies-settings-button:hover
{
    background-color: white;
}

.cookie-consent-banner-accept-cookies-button,
.cookie-consent-banner-reject-cookies-button
{
    background-color: #7069BF;
}

.cookie-consent-banner-accept-cookies-button:hover,
.cookie-consent-banner-reject-cookies-button:hover
{
    background-color: #4FAF9D;
}

.cookie-consent-button
{
    z-index: 9000;
    position: fixed;
    bottom: 0.5rem;
    left: 0.5rem;

    height: 2rem;
    width: 2rem;

    opacity: 0.5;
    filter: drop-shadow( 0 0.25rem 0.5rem rgba( 0, 0, 0, 0.4 ) );

    cursor: pointer;
    transition: transform 0.25s ease, opacity 0.25s ease;
}

.cookie-consent-button:hover
{
    transform: scale( 1.05 );

    opacity: 1;
}

.cookie-consent-button-tooltip
{
    position: absolute;
    top: 0.5rem;
    left: 2.5rem;

    padding: 0.125rem 0.25rem;

    display: none;

    opacity: 0;
    background-color: #252525;

    font-size: 0.6rem;
    white-space: nowrap;
    color: white;

    transition: opacity 0.25s ease;
}

.cookie-consent-button:hover .cookie-consent-button-tooltip
{
    display: block;

    opacity: 1;
}

.cookie-consent-dialog-container
{
    z-index: 9000;
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cookie-consent-dialog
{
    margin-left: 1rem;
    margin-right: 1rem;
    max-height: 80vh;
    max-width: 32rem;
    border-radius: 1rem;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: white;

    font-size: 0.9rem;
    font-weight: 500;
    color: #202020;
}

.cookie-consent-dialog-header
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cookie-consent-dialog-header-image
{
    height: 2rem;
    width: auto;
}

.cookie-consent-dialog-header-title
{
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
}

.cookie-consent-dialog-header-close-button
{
    margin-left: 1rem;
    height: 1rem;
    width: 1rem;

    cursor: pointer;
    transition: transform 0.25s ease;
}

.cookie-consent-dialog-header-close-button:hover
{
    transform: scale( 1.1 );
}

.cookie-consent-dialog-content
{
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    color: #404040;
}

.cookie-consent-dialog-description
{
    font-size: 0.8rem;
    color: #404040;
}

.cookie-consent-dialog-left-button-list
{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-start;
}

.cookie-consent-dialog-right-button-list
{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-end;
}

.cookie-consent-dialog-button
{
    border-radius: 0.25rem;
    padding: 0.25rem 1rem;

    display: inline-block;

    background-color: #6375D6;

    color: white;

    cursor: pointer;
    transition: background-color 0.25s ease;
}

.cookie-consent-dialog-button:hover
{
    background-color: #4FAF9D;
}

.cookie-consent-dialog-setting-list
{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cookie-consent-dialog-setting
{
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        "title . value"
        "description description description";
    align-items: center;
}

.cookie-consent-dialog-setting-title
{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    display: flex;
    grid-area: title;
    align-items: center;

    color: #202020;

    cursor: pointer;
}

.cookie-consent-dialog-setting-title:hover
{
    color: #404040;
}

.cookie-consent-dialog-setting-title-image
{
    margin-right: 0.5rem;
    height: 0.6rem;
    width: auto;
}

.cookie-consent-dialog-setting-title.is-grayed
{
    opacity: 0.8;
}

.cookie-consent-dialog-setting-title:hover .cookie-consent-dialog-setting-title-image
{
    opacity: 0.8;
}

.cookie-consent-dialog-setting-value
{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    grid-area: value;

    font-weight: 600;
    color: #4EAF9C;
}

.cookie-consent-dialog-setting-description
{
    border-radius: 0.5rem;
    padding: 1rem;

    grid-area: description;

    background-color: #F0F0F0;

    font-size: 0.8rem;

    cursor: pointer;
}

.cookie-consent-dialog-setting-switch
{
    position: relative;

    height: 1.5rem;
    width: 2.5rem;

    display: inline-block;
}

.cookie-consent-dialog-setting-input
{
    height: 0;
    width: 0;

    opacity: 0;
}

.cookie-consent-dialog-setting-slider
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: 1rem;

    background-color: #D0D0D0;

    cursor: pointer;
    transition: 0.4s;
}

.cookie-consent-dialog-setting-slider.is-readonly
{
    cursor: not-allowed;
}

.cookie-consent-dialog-setting-slider:before
{
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;

    height: 1rem;
    width: 1rem;
    border-radius: 50%;

    content: "";
    background-color: white;

    transition: 0.4s;
}

.cookie-consent-dialog-setting-input:checked + .cookie-consent-dialog-setting-slider
{
    background-color: #4EAF9C;
}

.cookie-consent-dialog-setting-input:checked + .cookie-consent-dialog-setting-slider:before
{
    transform: translateX( 1rem );
}
