.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;

  height: fit-content;
  width: 100%;
  padding: 2rem;

  background: linear-gradient(180deg, rgba(37, 37, 37, 0) 0%, #252525 80.21%);
}

@media screen and (max-width: 60em) {
  .footer-container {
    padding: 1rem;

    flex-direction: column-reverse;
    gap: 1rem;
    align-items: center;
  }
}

@media screen and (min-width: 60em) {
  .footer-year {
    transform: translateY( 0.17rem );
  }
}

@media screen and (max-width: 60em) {
  .footer-year {
    transform: translateY( 0.5rem );
  }
}

@media screen and (max-width: 30em) {
  .footer-year {
    transform: translateY( 0.8rem );
  }
}

.footer-label {
  height: 1.5rem;
}

@media screen and (max-width: 60em) {
  .footer-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY( -2rem );

    text-align: center;

    pointer-events: none;
  }
}

@media screen and (max-width: 30em) {
  .footer-label {
    transform: translateY( -1.5rem );
  }
}

.footer-label-text
{
  background: linear-gradient(251.92deg, #60e0c1 0%, #8e7ef1 100%);
  background-clip: text;

  color: transparent;
}

.footer-dev-logo {
  height: 1.5rem;

  filter: opacity(0.8) grayscale(0.8);

  transition: all 400ms ease-in-out;
}

.footer-dev-logo:hover {
  filter: opacity(1) grayscale(0);
}

.footer-dev-logo img {
  height: 100%;
  width: fit-content;

  object-fit: contain;
}

.footer-container p {
  width: fit-content;

  opacity: 0.6;

  font-size: 0.75rem;
}

.footer-container-link {
  gap: 2rem;
}
